<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  background: rgb(238, 238, 238);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .main {
    flex-grow: 1;
    margin-top: 2px;
    display: flex;
    overflow: hidden;
    font-size: 14px;
    .router {
      flex-grow: 1;
      margin: 24px;
      margin-bottom: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 18px;
        color: $black-1;
        margin-bottom: 12px;
      }
      .router-main {
        flex-grow: 1;
        overflow: hidden;
      }
    }
  }
}
</style>

<style>
.router-main > div {
  height: 100%;
  padding-bottom: 24px;
}
</style>

<template>
  <div class="index">
    <heads />
    <div class="main">
      <menus />
      <div class="router">
        <div class="title">{{ getRouterName }}</div>
        <div class="router-main">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";
import heads from "./components/head";
import menus from "./components/menu";
const ctx = getCurrentInstance();
//获取路名称
const getRouterName = computed(() => {
  return ctx.proxy.$route.matched
    .slice(1)
    .map((r) => r.meta.name)
    .join(" - ");
});
</script>